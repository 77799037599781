import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/SEOadvanced"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Card from "react-bootstrap/Card"
import CardColumns from "react-bootstrap/CardColumns"
import Carousel from "react-bootstrap/Carousel"

import { TiStar } from "react-icons/ti"

import Layout from "../components/layout"
import Features from "../components/Features"
import Callback from "../components/Callback"
import Onmap from "../components/Onmap"

export const query = graphql`
  query($slug: String!) {
    category: contentfulServiceCategories(categorySlug: { eq: $slug }) {
      categoryName
      categorySlug
      id
      serviceHeader1
      serviceHeroText {
        serviceHeroText
      }
      seoMetaTags {
        seoTitle
        seoDescription {
          seoDescription
        }
      }
      categoryImage {
        title
        fluid(maxWidth: 1024, background: "rgb:000000") {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    services: allContentfulServices(
      filter: { serviceCategory: { categorySlug: { eq: $slug } } }
    ) {
      edges {
        node {
          id
          serviceName
          servicePrice
          serviceDurationInMinutes
          serviceImage {
            fluid {
              src
            }
          }
          serviceDescription {
            content {
              content {
                value
              }
            }
          }
        }
      }
    }
    defaultimage: file(relativePath: { eq: "maniqure.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    categoryImages: allContentfulGallery(
      filter: { imagesCategory: { categorySlug: { eq: $slug } } }
    ) {
      nodes {
        images {
          id
          title
          fluid(maxWidth: 800, maxHeight: 600) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    testimonials: allContentfulTestimonials(
      filter: {
        testimonialCategories: { elemMatch: { categorySlug: { in: [$slug] } } }
      }
    ) {
      nodes {
        id
        testimonialAuthorName
        testimonialStars
        testimonialUrl
        testimonialCategories {
          categorySlug
        }
        testimonialScreen {
          fluid(maxWidth: 600, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        testimonialText {
          json
        }
      }
    }
  }
`

const CategoryTemplate = ({
  data: { category, services, categoryImages, testimonials },
}) => (
  <>
    <SEO
      title={category.seoMetaTags.seoTitle}
      description={category.seoMetaTags.seoDescription.seoDescription}
    />
    <Layout
      backgroundClass={`firstscreen ` + category.categorySlug}
      serviceHeader1={category.serviceHeader1}
      serviceHeroText={category.serviceHeroText.serviceHeroText}
    >
      <section className="services py-5">
        <h2 className="mb-5 text-center uppercase">Ціни на послуги</h2>
        {/* <Img fluid={category.categoryImage.fluid} alt={`на фото ` + category.categoryImage.title}/> */}
        <CardColumns className="container mb-5">
          {services.edges.map(service => (
            <Card key={`${service.node.id}`} className="text-white text-center">
              <Img
                fluid={category.categoryImage.fluid}
                alt={service.node.serviceName}
                className="service-image"
              />
              <Card.ImgOverlay className="image-gradient">
                <Card.Body>
                  <Card.Title>{service.node.serviceName}</Card.Title>
                  <Card.Text>
                    {service.node.servicePrice},00 ₴ (до{" "}
                    {service.node.serviceDurationInMinutes} хвилин)
                  </Card.Text>
                </Card.Body>
              </Card.ImgOverlay>
            </Card>
          ))}
        </CardColumns>
      </section>
      <Features />
      <section className="works-galery py-5">
        <h2 className="mb-5 text-center uppercase">Галерея</h2>
        <Container className="mb-5">
          {categoryImages.nodes.map(({ images }, idx) => (
            <Row key={idx}>
              {images
                .reverse()
                .slice(0, 12)
                .map(img => (
                  <Col
                    key={img.id}
                    lg={3}
                    md={4}
                    className="mb-4 h-100 d-block"
                  >
                    <Img
                      fluid={img.fluid}
                      alt={img.title}
                      className="img-fluid"
                    />
                  </Col>
                ))}
            </Row>
          ))}
        </Container>
      </section>
      <section className="carousel py-5">
        <h2 className="mb-5 text-center uppercase">Відгуки</h2>
        <Container fluid={false} className="mb-5">
          <Row>
            <Carousel
              indicators={false}
              controls={true}
              touch={true}
              fade={false}
            >
              {testimonials.nodes.map(testimonial => (
                <Carousel.Item key={testimonial.id}>
                  <Col lg={{ span: 8, offset: 2 }} md={12} sm={12}>
                    <div className="testimonial-author text-center mb-2">
                      {testimonial.testimonialAuthorName}
                    </div>
                    <div className="star-rating d-flex justify-content-center mb-4">
                      <TiStar />
                      <TiStar />
                      <TiStar />
                      <TiStar />
                      <TiStar />
                    </div>
                    <p className="text-center">
                      {/* {testimonial.testimonialStars} */}
                      {
                        testimonial.testimonialText.json.content[0].content[0]
                          .value
                      }
                    </p>
                    <Img
                      fluid={testimonial.testimonialScreen.fluid}
                      title={
                        `скріншот відгуку від ` +
                        testimonial.testimonialAuthorName
                      }
                      alt={`відгуку від ` + testimonial.testimonialAuthorName}
                      className="img-fluid"
                    />
                  </Col>
                </Carousel.Item>
              ))}
            </Carousel>
          </Row>
        </Container>
      </section>
      <Onmap />
      <Callback />
    </Layout>
  </>
)

export default CategoryTemplate
