import React from "react"

// Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

// Icons
import Locationicon from "../images/icons/location-icon.svg"
import Hearticon from "../images/icons/heart-icon.svg"
import Badgeicon from "../images/icons/badge-icon.svg"

const Features = () => (
  <section className="features py-5 text-center">
    <Container>
      <Row className="mt-5">
        <Col className="col-12 col-md-4 mb-5">
          <img
            src={Locationicon}
            className="feature-icon mb-4"
            alt="іконка зручно"
          />
          <p className="feature-title">Зручно добиратися</p>
          <p className="feature-description">
            Затишний салон "Le Beaute Studio" в ЖК Білий Шоколад у Білогородці.
            Не буває проблем з паркуванням.
          </p>
        </Col>
        <Col className="col-12 col-md-4 mb-5">
          <img
            src={Hearticon}
            className="feature-icon mb-4"
            alt="іконка стерильно"
          />
          <p className="feature-title">Чисто і стерильно</p>
          <p className="feature-description">
            Інструмент використовується тільки один раз після обов'язкової
            стерилізації. Одноразові витратні матеріали.
          </p>
        </Col>
        <Col className="col-12 col-md-4 mb-5">
          <img
            src={Badgeicon}
            className="feature-icon mb-4"
            alt="іконка якісно"
          />
          <p className="feature-title">Якісні матеріали</p>
          <p className="feature-description">
            Гелі та гель-лаки Luxio відмінно лягають, і носяться до 4х тижнів.
            Професійні воски для кожної ділянки шкіри.
          </p>
        </Col>
      </Row>
    </Container>
  </section>
)

export default Features
