import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"
import { IoMdCalendar } from "react-icons/io"

import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import Modal from "../components/Modal"

const Layout = ({ children, backgroundClass, serviceHeader1, serviceHeroText, ctaButtonText }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
    }}}
  `)

  return (
    <>
      <header
        className={backgroundClass}
      >
        <Navigation />
        <section className="hero py-5 text-center">
          <Container className="my-5">
            <h1 className="mb-4">{serviceHeader1}</h1>
            <p className="heroslogan mb-4">{serviceHeroText}</p>
            <Button
              variant="danger"
              className="btn-cta"
              onClick={() => setModalShow(true)}>
              <IoMdCalendar className="mr-1" />
              {ctaButtonText}
            </Button>
          </Container>
        </section>
      </header>
      <main>
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
         />
        {children}
      </main>
      <Footer siteTitle={data.site.siteMetadata.title} />
    </>
  )
}

Layout.defaultProps = {
  backgroundClass: `firstscreen`,
  backgroundImage: `firstscreen`,
  serviceHeader1: `Б'юті-майстер у Білогородці`,
  serviceHeroText: `Більше не потрібно їхати до Києва за якісним манікюром, педикюром або за швидкою депіляцією. Обери зручний час та запишись`,
  ctaButtonText: `Обрати час і записатися`
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
