import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Onmap = () => (
  <section className="salon-onmap py-5">
    <h2 className="mb-5 text-center uppercase">Як знайти?</h2>
    <Container className="mb-5">
      <Row>
        <Col xs={12} sm={12} md={6} lg={8} className="mb-5 mb-md-0">
          {/* https://console.cloud.google.com/apis/credentials/key/90822410-571f-49cf-a3f9-5c0c5ee0a818?project=beautymaster */}
          <iframe
            title="googlemap"
            className="mapcontainer"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2543.73458065503!2d30.233599415942315!3d50.390146199589054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4b54d1a0d6b09%3A0x760aaa163b879be8!2sManikyur%2C%20Pedykyur%2C%20Depilyatsiya%2C%20Vizazh!5e0!3m2!1sen!2sua!4v1572353740334!5m2!1sen!2sua"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </Col>
        <Col xs={12} sm={10} md={6} lg={4}>
          <h4>Адреса салону</h4>
          <p>Білогородка, ЖК Білий Шоколад, Михайла Величка, 1А</p>
          <h4>Робочі години</h4>
          <p>
            Вівторок-Субота: 9:00-18:00
            <br />
            Перерва: 13:00-14:00
          </p>
          <h4>Телефонуйте</h4>
          <p>+38(097) 308-11-96</p>
        </Col>
      </Row>
    </Container>
  </section>
)
export default Onmap
