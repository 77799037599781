import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import InputGroup from "react-bootstrap/InputGroup"
import { FormControl } from "react-bootstrap"

const Callback = () => (
  <section className="callback py-5">
    <h2 className="mb-5 text-center uppercase">Записатися за телефоном</h2>
    <Container className="mb-5">
      <p className="text-center mb-4">
        Залиште, будь ласка, номер, і я передзвоню протягом години.
      </p>
      <Row className="justify-content-center">
        <Col sm={12} md={8} lg={6}>
          <Form
            name="callback-form"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            method="post"
            action="/success/"
          >
            <input type="hidden" name="form-name" value="callback-form" />
            <InputGroup className="mb-3" size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">+38</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                required
                placeholder="ваш номер"
                aria-label="Телефон"
                aria-describedby="callbacknum"
                name="phone"
              />
              <InputGroup.Append>
                <Button variant="danger" size="lg" type="submit">
                  Надіслати
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  </section>
)

export default Callback
