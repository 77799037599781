import React from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

const ContactModal = props => {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="uppercase">
            Записатися
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Відправте контакти і ми разом підберемо зручний найближчий час.</p>
          <Form
            name="modalform"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="/success/"
          >
            <input type="hidden" name="form-name" value="modalform" />
            <Form.Group controlId="formPhone">
              <Form.Label>Телефон *</Form.Label>
              <Form.Control
                type="text"
                name="modalform-phone"
                placeholder="+380..."
                required
              />
            </Form.Group>
            <Form.Group controlId="formName">
              <Form.Control
                type="text"
                name="modalform-name"
                placeholder="Ім'я"
              />
            </Form.Group>
            <Form.Group controlId="formServices">
              <Form.Control
                name="modalform-service"
                as="select"
                defaultValue={1}
              >
                <option value={1} disabled>
                  Оберіть послугу
                </option>
                <option value={`depilyatsiya`}>Депіляція</option>
                <option value={`pedykyur`}>Педикюр</option>
                <option value={`manikyur`}>Манікюр</option>
                <option value={`kompleks`}>Комплекс послуг</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                name="modalform-checkbox"
                type="checkbox"
                label="Згода з правилами сайту"
                required
              />
            </Form.Group>
            <Button variant="danger" type="submit" size="lg" block>
              Надіслати
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ContactModal
